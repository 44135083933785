@import "variables";
$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;
$icon-cubefocus: "\e905";
$icon-caretup: "\e904";
$icon-caretdown: "\e902";
$icon-loop: "\e903";
$icon-cart: "\e900";
$icon-user: "\e901";
@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot');
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-cubefocus {
    &:before {
        content: $icon-cubefocus;
    }
}

.icon-caretup {
    &:before {
        content: $icon-caretup;
    }
}

.icon-caretdown {
    &:before {
        content: $icon-caretdown;
    }
}

.icon-loop {
    &:before {
        content: $icon-loop;
    }
}

.icon-cart {
    &:before {
        content: $icon-cart;
    }
}

.icon-user {
    &:before {
        content: $icon-user;
    }
}
$enable-responsive-font-sizes: false;
@import 'node_modules/bootstrap/scss/functions'; // Import Bootstrap's functions
@import 'node_modules/bootstrap/scss/variables'; // Import Bootstrap's variables
@import 'node_modules/bootstrap/scss/mixins'; // Import Bootstrap's mixins      

@function stripUnit($value) {
    @return $value / ($value * 0 + 1);
}

@function rem($pxValue) {
    @return #{stripUnit($pxValue) / stripUnit($html-font-size)}rem;
}

// override bootstrap vars
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1304px
);
$theme-colors: (
    "primary": #855041,
    "secondary": #204139,
    "dark": #000000,
    "green": #204139,
    "green-dark": #0D2620,
    "light": #fff,
    "beige":#FDE6D8,
    "ocre":#BC9565,
    "grey-300":#F7F1ED,
    "grey-500": #D6C9C2,
    "grey-700":#867D79,
    "grey-900":#FFF9F5,

);
$utilities-border-colors: ("primary": #855041,
    "secondary": #204139,
    "dark": #000000,
    "green": #204139,
    "green-dark": #0D2620,
    "light": #fff,
    "beige":#FDE6D8,
    "ocre":#BC9565,
    "grey-300":#F7F1ED,
    "grey-500": #D6C9C2,
    "grey-700":#867D79,
    "grey-900":#FFF9F5,

);
$headings-font-family: "Caviar Dreams";
$font-family-base: "Calibri";
$html-font-size: 16px;
$border-radius: 24px;

.ff-body {
    font-family: $font-family-base !important;
}

.ff-h {
    font-family: $headings-font-family !important;
}
//
// badge
$badge-font-size: 14px;
$badge-font-weight: 400;
$badge-padding-y: 8px;
$badge-padding-x: 16px;
$badge-border-radius: 24px;
// font size

$font-sizes: (
    0: 0px,
    1: 64px,
    2: 48px,
    3: 32px,
    4: 24px,
    5: 18px,
    6: 16px,
    7: 14px
);
// custom maps
$custom-utilities: (
    "height": (property: height,
        class: h,
        values: (25: 25%,
            50: 50%,
            60: 60%,
            65: 65%,
            70: 70%,
            75: 75%,
            80: 80%,
            100: 100%,
            auto: auto))
);
$utilities: ();
// Merge the maps
$utilities: map-merge($utilities, $custom-utilities);

$position-values: (
    0: 0,
    30: 30%,
    40: 40%,
    50: 50%,
    60: 60%,
    70: 70%,
    80: 80%,
    80px: 80px,
    100: 100%);
$custom-spacers: (
    0: 0,
    1: $spacer * .25, //4px
    2: $spacer * .5, //8px
    3: $spacer,     //16
    4: $spacer * 1.5, //24
    5: $spacer * 3, //48
    6: $spacer * 2.5, //40px
    7: $spacer * 4, //64
    8: $spacer * 4.5, //72
    9: $spacer * 5, //80px
    10: $spacer * 6.25, //100px
    11: $spacer * 7.5, //120px
    12: $spacer * 2, //32px
    13: $spacer * 0.75, //12px
    14: $spacer * 2.1875 //35px
    );
$spacers: map-merge($spacers, $custom-spacers);
$enable-negative-margins: true;
// Allows for customizing button radius independently from global border radius
$btn-border-radius: rem(24);
$btn-border-radius-sm: rem(40);
$btn-border-radius-lg: rem(40);

$input-btn-padding-y: rem(12px);
$input-btn-padding-x: rem(24px);
$input-btn-font-size: rem(16px);
$input-btn-padding-y-sm: rem(12px);
$input-btn-padding-x-sm: rem(16px);
$input-btn-font-size-sm: rem(14px);
$input-btn-padding-y-lg: rem(20px);
$input-btn-padding-x-lg: rem(32px);
$input-btn-font-size-lg: rem(16px);

//---------------------------------

$input-padding-y: 12px;
$input-padding-x: 16px;
$input-font-family: $font-family-base;
$input-font-size: 14px;
$input-font-weight: 400;
$input-line-height: 24px;

$input-padding-y-sm: 12px;
$input-padding-x-sm: 16px;
$input-font-size-sm: 14px;

$input-padding-y-lg: 12px;
$input-padding-x-lg: 16px;
$input-font-size-lg: 14px;

$input-bg: #fff;
$input-disabled-color: #F7F1ED;
$input-disabled-bg: #fff;
$input-disabled-border-color: #F7F1ED;

$input-color: #000;
$input-border-color: #D6C9C2;
$input-box-shadow: 0 none;

$input-border-radius: 24px;
$input-border-radius-sm: 24px;
$input-border-radius-lg: 24px;

$input-focus-bg: $input-bg;
$input-focus-border-color: #000;
$input-focus-color: $input-color;
$input-focus-box-shadow: 0 none;

$input-placeholder-color: #D6C9C2;
$input-plaintext-color: #000;

$input-transition: all .15s ease-in-out;

$form-color-width: 3rem;

$form-label-margin-bottom: .5rem;
$form-label-font-size: 1rem;
$form-label-font-style: null;
$form-label-font-weight: 700;
$form-label-color: #000;
//------------------------
$form-check-input-width: 20px;
$form-check-min-height: 20px;
$form-check-padding-start: 4px;
$form-check-margin-bottom: .125rem;
$form-check-label-color: null;
$form-check-label-cursor: null;
$form-check-transition: null;

$form-check-input-active-filter: brightness(90%);

$form-check-input-bg: Transparent;
$form-check-input-border: 1px solid #000;
$form-check-input-border-radius: 0;
$form-check-input-focus-border: #867D79;
$form-check-input-focus-box-shadow: 0 none;

$form-check-input-checked-color: white;
$form-check-input-checked-bg-color: black;
$form-check-input-checked-border-color: black;

$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'><path d='M10.7653 3.64031L4.76534 9.64031C4.73051 9.67517 4.68915 9.70283 4.64363 9.7217C4.5981 9.74058 4.54931 9.75029 4.50003 9.75029C4.45074 9.75029 4.40195 9.74058 4.35642 9.7217C4.3109 9.70283 4.26954 9.67517 4.23471 9.64031L1.60971 7.01531C1.53935 6.94494 1.49982 6.84951 1.49982 6.74999C1.49982 6.65048 1.53935 6.55505 1.60971 6.48468C1.68008 6.41432 1.77551 6.37479 1.87503 6.37479C1.97454 6.37479 2.06997 6.41432 2.14034 6.48468L4.50003 8.84484L10.2347 3.10968C10.3051 3.03932 10.4005 2.99979 10.5 2.99979C10.5995 2.99979 10.695 3.03932 10.7653 3.10968C10.8357 3.18005 10.8752 3.27548 10.8752 3.37499C10.8752 3.47451 10.8357 3.56994 10.7653 3.64031Z' fill='#{$form-check-input-checked-color}' /></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");

$form-check-input-indeterminate-color: $component-active-color;
$form-check-input-indeterminate-bg-color: $component-active-bg;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

$form-check-input-disabled-opacity: .5;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity;
$form-check-btn-check-disabled-opacity: $btn-disabled-opacity;

$form-check-inline-margin-end: 1rem;


$form-select-padding-y: $input-padding-y;
$form-select-padding-x: $input-padding-x;
$form-select-font-family: $input-font-family;
$form-select-font-size: $input-font-size;
$form-select-indicator-padding: $form-select-padding-x * 3; // Extra padding for background-image
$form-select-font-weight: $input-font-weight;
$form-select-line-height: $input-line-height;
$form-select-color: $input-color;
$form-select-bg: $input-bg;
$form-select-disabled-color: null;
$form-select-disabled-bg: $input-disabled-bg;
$form-select-disabled-border-color: $input-disabled-border-color;
$form-select-bg-position: right $form-select-padding-x center;
$form-select-bg-size: 16px 12px; // In pixels because image dimensions
$form-select-indicator-color: $gray-800;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>");

$form-select-feedback-icon-padding-end: $form-select-padding-x * 2.5 + $form-select-indicator-padding;
$form-select-feedback-icon-position: center right $form-select-indicator-padding;
$form-select-feedback-icon-size: $input-height-inner-half $input-height-inner-half;

$form-select-border-width: $input-border-width;
$form-select-border-color: $input-border-color;
$form-select-border-radius: $input-border-radius;
$form-select-box-shadow: $box-shadow-inset;

$form-select-focus-border-color: $input-focus-border-color;
$form-select-focus-width: $input-focus-width;
$form-select-focus-box-shadow: 0 none;

$form-select-padding-y-sm: $input-padding-y-sm;
$form-select-padding-x-sm: $input-padding-x-sm;
$form-select-font-size-sm: $input-font-size-sm;
$form-select-border-radius-sm: $input-border-radius-sm;

$form-select-padding-y-lg: $input-padding-y-lg;
$form-select-padding-x-lg: $input-padding-x-lg;
$form-select-font-size-lg: $input-font-size-lg;
$form-select-border-radius-lg: $input-border-radius-lg;

$form-select-transition: $input-transition;



$navbar-toggler-padding-y: .25rem;
$navbar-toggler-padding-x: .75rem;
$navbar-toggler-font-size: 24px;
$navbar-toggler-border-radius: 0;
$navbar-toggler-focus-width: $btn-focus-width;
$navbar-toggler-transition: box-shadow .15s ease-in-out;

$navbar-light-color: #000;
$navbar-light-hover-color: #855041;
$navbar-light-active-color: #855041;
$navbar-light-disabled-color: rgba(var(--#{$prefix}emphasis-color-rgb), .3);
$navbar-light-icon-color: #000;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'><path d='M6 8H18M6 12H18M6 16H18' stroke='black' stroke-width='2' stroke-linecap='round' /></svg>");

$navbar-light-toggler-border-color: rgba(var(--#{$prefix}emphasis-color-rgb), .15);
$navbar-light-brand-color: $navbar-light-active-color;
$navbar-light-brand-hover-color: $navbar-light-active-color;

// import bootstrap 

@import "node_modules/bootstrap/scss/bootstrap";
@import 'node_modules/owl.carousel/src/scss/owl.carousel.scss';
@import 'node_modules/owl.carousel/src/scss/owl.theme.default.scss';
//root vars
:root{
    --bs-link-color-rgb: #000;

    --woocommerce: #855041;
    --wc-green: #204139;
    --wc-red: #a00;
    --wc-orange: #ffba00;
    --wc-blue: #2ea2cc;
    --wc-primary: #855041;
    --wc-primary-text: white;
    --wc-secondary: #ebe9eb;
    --wc-secondary-text: #515151;
    --wc-highlight: #77a464;
    --wc-highligh-text: white;
    --wc-content-bg: #fff;
    --wc-subtext: #767676;
    --bs-grey-700: #867D79;

}
.modal{
    --bs-modal-padding: 80px;
    --bs-modal-header-padding: 80px;
    --bs-modal-border-radius: 0;
    --bs-modal-width: 800px;
    --bs-modal-bg: #FFF9F5;
    @include media-breakpoint-down(lg) {
        --bs-modal-padding: 24px;
        --bs-modal-header-padding: 24px;
        --bs-modal-border-radius: 0;
        --bs-modal-width: 100%;
    }
    #scrollbloc{
        height: 40vh;
        @include media-breakpoint-down(lg) {
            height: auto;
        }
    }
    .woocommerce-variation-add-to-cart{
        padding-bottom: 0;
    }
}
.modal-dialog{
    height: 90vh;
    margin-top: 0;
    margin-right: inherit !important;
    @include media-breakpoint-down(lg) {
         height:auto;
    }
}
.modal.fade .modal-dialog{
    transform: translate(50px, 0);
}
.modal.show .modal-dialog{
    transform: none;

}
.modal-header{
    border-bottom: 0 none;
}
.modal-body{
    padding-top: 0;
    padding-bottom: 0;
}
.btn-close {
    --bs-btn-close-color: #fff;
    --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
    --bs-btn-close-opacity: 1;
    --bs-btn-close-hover-opacity: 1;
    --bs-btn-close-focus-shadow: 0 none;
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    box-sizing: content-box;
    width: 60px;
    height: 60px;
    padding: 13px;
    color: var(--bs-btn-close-color);
    background: map-get($theme-colors , "primary" ) var(--bs-btn-close-bg) center/1em auto no-repeat;
    border: 0;
    border-radius: 100%;
    opacity: var(--bs-btn-close-opacity);
    transform: translateY(-40px) translateX(20px);
    @include media-breakpoint-down(lg) {
        transform: translateY(0) translateX(0);
        width: 30px;
        height: 30px;
        padding: 6px;
    }
}

.breadcrumb {
    --bs-breadcrumb-padding-x: 0;
    --bs-breadcrumb-padding-y: 0;
    --bs-breadcrumb-margin-bottom: 1rem;
    --bs-breadcrumb-bg: ;
    --bs-breadcrumb-border-radius: 0;
    --bs-breadcrumb-divider-color: var(--bs-grey-700);
    --bs-breadcrumb-item-padding-x: 4px;
    --bs-breadcrumb-item-active-color: #000;
    display: flex;
    flex-wrap: wrap;
    padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
    margin-bottom: var(--bs-breadcrumb-margin-bottom);
    font-size: var(--bs-breadcrumb-font-size);
    list-style: none;
    background-color: var(--bs-breadcrumb-bg);
    border-radius: var(--bs-breadcrumb-border-radius);
    a{
        color: var(--bs-grey-700);
        text-decoration: none;
    }
}
.btn{
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.75rem;
  --bs-btn-font-family: $font-family-base;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 700;
  --bs-btn-line-height: 1.25rem;
  --bs-btn-border-radius: 1.5rem;
  --bs-btn-disabled-opacity: 1;

}
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #855041;
    --bs-btn-border-color: #855041;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #BC9565;
    --bs-btn-hover-border-color: #BC9565;
    --bs-btn-focus-shadow-rgb: 151, 106, 94;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #BC9565;
    --bs-btn-active-border-color: #BC9565;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #D6C9C2;
    --bs-btn-disabled-border-color: #D6C9C2;
    text-transform: uppercase;
    font-weight: bold;
    svg {
        path {
            fill: var(--bs-white);
        }
    }
}
.btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #204139;
    --bs-btn-border-color: #204139;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0D2620;
    --bs-btn-hover-border-color: #0D2620;
    --bs-btn-focus-shadow-rgb: 65, 94, 87;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0D2620;
    --bs-btn-active-border-color: #0D2620;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #D6C9C2;
    --bs-btn-disabled-border-color: #D6C9C2;
}
.btn-light {
    --bs-btn-color: #000;
    --bs-btn-bg: #fff;
    --bs-btn-border-color: #fff;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #F7F1ED;
    --bs-btn-hover-border-color: #F7F1ED;
    --bs-btn-focus-shadow-rgb: 247, 241, 237;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #F7F1ED;
    --bs-btn-active-border-color: #bfbfbf;
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: #867D79;
    --bs-btn-disabled-bg: #D6C9C2;
    --bs-btn-disabled-border-color: #D6C9C2;
}
.btn-sm,
.btn-group-sm>.btn {
    --bs-btn-padding-y: 0.75rem;
    --bs-btn-padding-x: 1rem;
    --bs-btn-font-size: 0.875rem;
    --bs-btn-border-radius: 1.5rem;
    line-height: 1.125rem;
}
.btn-lg,
.btn-group-lg>.btn {
    --bs-btn-padding-y: 1.25rem;
    --bs-btn-padding-x: 2rem;
    --bs-btn-font-size: 1rem;
    --bs-btn-border-radius: 40px;
    line-height: 1.25rem;
}
.form-check .form-check-input{
    margin-left: 0;
    margin-top: 0;
    margin-right: 8px;
}
// ----------fonts---
@font-face {
    font-family: 'Caviar Dreams';
    src: local('Caviar Dreams Italic'), local('CaviarDreams-Italic'),
        url('../fonts/CaviarDreams-Italic.woff2') format('woff2'),
        url('../fonts/CaviarDreams-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Caviar Dreams';
    src: local('Caviar Dreams'), local('CaviarDreams'),
        url('../fonts/CaviarDreams.woff2') format('woff2'),
        url('../fonts/CaviarDreams.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Caviar Dreams';
    src: local('Caviar Dreams Bold Italic'), local('CaviarDreams-BoldItalic'),
        url('../fonts/CaviarDreams-BoldItalic.woff2') format('woff2'),
        url('../fonts/CaviarDreams-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Caviar Dreams';
    src: local('Caviar Dreams Bold'), local('CaviarDreams-Bold'),
        url('../fonts/CaviarDreams-Bold.woff2') format('woff2'),
        url('../fonts/CaviarDreams-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Calibri';
    src: url('../fonts/Calibri-Bold.eot');
    src: local('Calibri Bold'), local('Calibri-Bold'),
        url('../fonts/Calibri-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Calibri-Bold.woff2') format('woff2'),
        url('../fonts/Calibri-Bold.woff') format('woff'),
        url('../fonts/Calibri-Bold.ttf') format('truetype'),
        url('../fonts/Calibri-Bold.svg#Calibri-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibri';
    src: url('../fonts/Calibri-BoldItalic.eot');
    src: local('Calibri Bold Italic'), local('Calibri-BoldItalic'),
        url('../fonts/Calibri-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Calibri-BoldItalic.woff2') format('woff2'),
        url('../fonts/Calibri-BoldItalic.woff') format('woff'),
        url('../fonts/Calibri-BoldItalic.ttf') format('truetype'),
        url('../fonts/Calibri-BoldItalic.svg#Calibri-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calibri';
    src: url('../fonts/Calibri.eot');
    src: local('Calibri'),
        url('../fonts/Calibri.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Calibri.woff2') format('woff2'),
        url('../fonts/Calibri.woff') format('woff'),
        url('../fonts/Calibri.ttf') format('truetype'),
        url('../fonts/Calibri.svg#Calibri') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibri';
    src: url('../fonts/Calibri-Italic.eot');
    src: local('Calibri Italic'), local('Calibri-Italic'),
        url('../fonts/Calibri-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Calibri-Italic.woff2') format('woff2'),
        url('../fonts/Calibri-Italic.woff') format('woff'),
        url('../fonts/Calibri-Italic.ttf') format('truetype'),
        url('../fonts/Calibri-Italic.svg#Calibri-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

.accordion {
    --bs-accordion-color: #000;
    --bs-accordion-bg: transparent;
    --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    --bs-accordion-border-color: var(--bs-border-color);
    --bs-accordion-border-width: 0 0 2px 0;
    --bs-accordion-border-radius: 0;
    --bs-accordion-inner-border-radius: 0;
    --bs-accordion-btn-padding-x: 24px;
    --bs-accordion-btn-padding-y: 24px;
    --bs-accordion-btn-color: var(--bs-body-color);
    --bs-accordion-btn-bg: transparent;
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-focus-border-color: transparent;
    --bs-accordion-btn-focus-box-shadow: 0 none;
    --bs-accordion-body-padding-x: 16px;
    --bs-accordion-body-padding-y: 16px;
    --bs-accordion-active-color: #000;
    --bs-accordion-active-bg: #F7F1ED;
}
.accordion-button {
    font-size: 24px;
    font-weight: bold;
    font-family: "Calibri";
    color: #867D79;
    position: relative;
    padding-right: 60px;
    &:after{
        position: absolute;
        right: 28px;
        top: 28px;
    }
}
.accordion-item {
    border-bottom: 2px solid #D6C9C2;
    
}
.accordion-body{
    color: #867D79;
}
//================================================================================
.badge {
    border: 1px solid;
    line-height: 128%;
    font-weight: 400;
    margin-right: 16px;
    margin-bottom: 0;
    background: transparent;
    backdrop-filter: blur(4px);
    font-size: 14px;
}
.img-cadre{
    position: relative;
    &:after{
        content: '';
        border-radius: 400px 400px 0px 0px;
        border: 2px solid map-get($theme-colors, 'ocre');
        position: absolute;
        width: 84.21%;
        height: 84.21%;
        right: 50%;
        top: 50%;
        transform: translateX(50%) translateY(-50%);
    }
}
 .bg-opacity-5{
    background-color: var(--transparent-white-5, rgba(255, 255, 255, 0.05));
    &.list-group-item-action{
        &:hover, &:active, &:focus{
            background-color: map-get($theme-colors, "green-dark");
        }
    }
 }
.btn-outline-light {
    background: var(--transparent-light-10, rgba(0, 0, 0, 0.10));
    backdrop-filter: blur(4px);
}

// rounded colection image 
.rounded-top-c {
    border-radius: 400px 400px 0 0;
}
.link-rounded{
    width: 60px;
    height: 60px;
    padding: 13px;
    &.stretched-link.opacity-0 {
        width: 1px;
        height: 1px;
        padding: 0 !important;
        margin: 0 !important;
    }
    svg,img{
        top: 50%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

// generals

html {
    @media (min-width:1024px) and (max-width: 1400px) {
        font-size: 14px;
    }

}

header {
    height: 134px;
    background-color: map-get($theme-colors, "grey-900" );
    @include media-breakpoint-down(lg) {
        height: 88px;
    }
    &.woocommerce-Address-title.title{
        height: auto;
        background-color: transparent;
    }
    #menu-pages-infos-legales{
        padding-top: 30px;
        li{
            padding: 0;
        }
        .nav-link{
            color: #000 !important;
            font-family: Calibri;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%;
            padding: 24px 0 0;
        }
    }
}

#quadmenu.quadmenu-is-horizontal {
    .quadmenu-navbar-nav {
        >li.quadmenu-item-type-tabs>.quadmenu-dropdown-menu>ul>li>ul.quadmenu-tabs>li.quadmenu-item-type-tab {
            margin-bottom: 0 !important;
        }
    }
}

#quadmenu {
    .quadmenu-navbar-nav {
        >li.quadmenu-has-title>a>.quadmenu-item-content>.quadmenu-text {
            text-decoration: underline;
            text-underline-offset: 0.375em !important;
        }

        li.quadmenu-item {
            .quadmenu-item-content {
                padding: 0;

                .quadmenu-icon {
                    font-size: rem(24) !important;
                }
            }

            &.quadmenu-item-object-icon:not(.quadmenu-item-type-button) {
                margin: 0px 16px 0px 16px;
            }
            &.current-menu-item, &.current-menu-ancestor {
                &>a {
                    font-weight: bold;
                    text-decoration-thickness: 2px;
                    text-decoration: underline solid map-get($theme-colors, "secondary") 2px;
                    color: map-get($theme-colors, "secondary" );
                    &>span{
                        color: map-get($theme-colors, "secondary" );
                        &>span{
                            border-bottom: 1px solid map-get($theme-colors, "secondary");
                            padding-bottom: 1px;
                        }
                    }
                }
            }
        }

        .quadmenu-dropdown-menu {
            li.quadmenu-item {
                >a {
                    text-decoration: none;

                    >.quadmenu-item-content {
                        padding: rem(24) rem(24) rem(24) rem(40);
                        color: map-get($theme-colors, "grey-700");
                    }
                }

                &:hover>a>.quadmenu-item-content,
                &.open>a>.quadmenu-item-content {
                    color: $black;
                }

            }

            &.quadmenu-is-horizontal {
                >li.quadmenu-item-type-tabs {
                    .quadmenu-dropdown-menu {
                        >ul>li>ul.quadmenu-tabs>li.quadmenu-item-type-tab {
                            margin-bottom: 0 !important;

                            &:hover,
                            &.open {
                                background-color: map-get($theme-colors, "grey-300");
                            }
                        }
                    }
                }
            }
        }
    }
}

#quadmenu:not(.quadmenu-is-horizontal).quadmenu-mobile-shadow-show {
    box-shadow: none !important;
}
#quadmenu.quadmenu-default_theme .quadmenu-navbar-nav>li:not(.quadmenu-item-type-button).quadmenu-has-link:hover>a>.quadmenu-item-content,
#quadmenu.quadmenu-default_theme .quadmenu-navbar-nav>li:not(.quadmenu-item-type-button).quadmenu-has-link.open>a>.quadmenu-item-content{
    color: $black;
    opacity: 0.8;
}
#quadmenu.quadmenu-default_theme {
    background-color: map-get($theme-colors, "grey-900") !important;
    @include media-breakpoint-down(lg) {
        background-color: map-get($theme-colors, "grey-900") !important;

        .quadmenu-navbar-nav>li:not(.quadmenu-item-type-button) {
            margin: 0 !important;
            border-radius: 0 !important;
        }

        .quadmenu-navbar-nav>li.quadmenu-item>a,
        .quadmenu-navbar-nav>li.quadmenu-item>form {
            font-size: 32px !important;
        }

        &:not(.quadmenu-is-horizontal) .quadmenu-navbar-nav li.quadmenu-item .quadmenu-item-content,
        &:not(.quadmenu-is-horizontal) .quadmenu-navbar-nav li.quadmenu-item .quadmenu-item-content>.quadmenu-caret {
            padding: 24px 0 !important;
        }
    }
}
#quadmenu .quadmenu-navbar-nav li.quadmenu-item.btn-menu>a {
    position: relative;
    direction: initial;
    height: 100%;
    width: auto;
    flex-direction: column;
    display: inline-flex;
    justify-content: center;
    padding: 0 !important;
    text-decoration: none !important;
    
    &>span>span {
        @extend .btn;
        background-color: map-get($theme-colors, "primary");
        border-radius: 24px;
        padding: 12px 24px;
        text-decoration: none;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;
        text-decoration: none !important;
    }
    &:hover{
        &>span>span{
            background-color: map-get($theme-colors, "ocre");
            color: $white;
        }
    }
}

#quadmenu .quadmenu-navbar-nav li.quadmenu-item.btn-menu {
    &>a {
        @include media-breakpoint-down(lg) {
            position: relative;
            direction: initial;
            height: inherit;
            width: auto !important;
            flex-direction: column;
            display: inline-flex;
            justify-content: center;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

#quadmenu.quadmenu-default_theme:not(.quadmenu-is-horizontal) .quadmenu-navbar-nav li.quadmenu-item.btn-menu .quadmenu-item-content,
#quadmenu.quadmenu-default_theme:not(.quadmenu-is-horizontal) .quadmenu-navbar-nav li.quadmenu-item.btn-menu .quadmenu-item-content>.quadmenu-caret {
    @include media-breakpoint-down(lg) {
        padding: 0 !important;
    }
}

#quadmenu.quadmenu-default_theme .quadmenu-navbar-nav>li:not(.quadmenu-item-type-button).btn-menu {
    @include media-breakpoint-down(lg) {
        margin-top: 65px !important;
    }
}

#quadmenu .quadmenu-navbar-nav>li.quadmenu-has-title>a>.quadmenu-item-content>.quadmenu-text {
    @include media-breakpoint-down(lg) {
    text-decoration: none !important;
    }
}
body {
    background: map-get($theme-colors, "grey-900" );
    &.admin-bar{
        header{
            .fixed-top{
                top: 32px;
            }
        }
    }
}
textarea{
    resize: none;
    height: 100px !important;
}
.footer {
    background: url(../img/bg-footer.svg) no-repeat;
    background-size: cover;
    @include media-breakpoint-down(lg) {
        background: transparent;
    }
    .logo-footer{
        width: 172px;
        @include media-breakpoint-down(lg) {
            width: 100px;
        }
    }
    .nav{
        a{
            color: $white;
            transition: all ease-in-out 0.2s;
            &:hover{
                opacity: 0.5;
            }
        }
        .nav-item{
            color: $white;
            span{
                color: $white;

            }
        }
    }
}

.navbar {
    height: 134px;
    padding: rem(32px) rem(40);
    background-color: map-get($theme-colors, "grey-900");
    @include media-breakpoint-down(lg) {
        height: 88px;
        padding: 24px 0;
    }
    .logo {
        height: rem(70);
        @include media-breakpoint-down(lg) {
            height: 40px;
            width: auto;
        }
    }
    &>.container-fluid{
    @include media-breakpoint-down(lg) { 
        margin-left: -1px;
    }
    }
    
}
.navbar-brand{
    padding: 0;
    @include media-breakpoint-down(lg) {
        padding: 0 0 0 24px;
    }
}
.navbar-collapse{
    @include media-breakpoint-down(lg) {
        height: 100vh;
        padding-left: 24px;
        padding-right: 24px;
    }
    @include media-breakpoint-down(lg) {
        background-color: map-get($theme-colors, "grey-900" );
    }
    &.show{
        @include media-breakpoint-down(lg) {
            height: 100vh;
            overflow: auto;
        }
    }
    .nav-link{
        @include media-breakpoint-down(lg) {
            font-family: $headings-font-family;
            color: #000;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
            text-transform: uppercase;
            padding: 2.5vh 0 ;
        }
    }
}
// heading
h1 {
    // color: var(--grey-grey-100, #FFF);
    text-align: center;
    font-size: rem(64);
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
}
.wysiwyg{
    h2,h3,h4,h5,h6{
        font-weight: bold;
    }
    p{
        font-family: Calibri;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        color: #000;
    }
}
.collection-content{
    .wysiwyg {
    
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: $white;
            }
    
            p {
                color: $white;
            }
        }
}
// blocs
// banner
.jumbotron {
    //min-height: 100vh;
    @include media-breakpoint-down(lg) {
        //min-height: 80vh;
    }
    &:before {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%);
        position: absolute;
    }

    .content-bloc {
        width: 50%;

       @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }

    .img-banner {
        width: 100%;
    }

    h1 {
        margin-bottom: rem(40);
        color: var(--grey-grey-100, #FFF);
        @media (max-width: 1400px) {
            line-height: 125%;
        }
    }

    p {
        color: var(--grey-grey-100, #FFF);
        font-size: rem(24px);
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        margin-bottom: rem(40);
    }
    .btn{
        font-size: 14px !important;
        &.btn-light{
            font-weight: bold !important;
        }
        &.btn-outline-light{
            font-weight: normal !important;
        }
    }
}

// bloc reassurance
.animated-block{
    transition: all 300ms cubic-bezier(.455, .03, .515, .955),
        opacity 300ms ease;
        opacity: 0;
        transform: translateY(25%);
}
.scroll-animation{
    opacity: 1;
    transform: translateY(0);
}
.bloc-reassurance {
    background-color: var(--bs-green);
    position: relative;
    overflow: hidden;
    color: var(--bs-white);
    height: rem(158);
   @include media-breakpoint-down(lg) {
        height: auto;
    }
    h3{
        font-family: $font-family-base;
    }
    .rellax {
        width: 100%;
        height: 1684px;
        background: url(../img/lines.svg) no-repeat 50% 50%;
        background-size: cover;
        position: absolute;
        top: -1500px;
        opacity: 0.2;
        @include media-breakpoint-down(lg) {
            top: -1168px;
        }
    }
    .rea-item{
        @include media-breakpoint-down(lg) {
               padding: 24px !important;
               margin-bottom: 24px !important;
            }
            .flex-grow-1.mx-6{
                margin-right: 0 !important;
            }
            h3{
                @include media-breakpoint-down(lg) {
                    font-size: 16px !important;
                    line-height: 125% !important;
                }
            }
            p{
                @include media-breakpoint-down(lg) {
                    font-size: 16px !important;
                    line-height: 125% !important;
                }
            }
    }
}

// products
.description-produit{
    background-color: white;
}
.woocommerce-variation-description, .woocommerce-variation-price{
    display: none;
}
.product-col {
    width: rem(360);
    @include media-breakpoint-down(lg) {
        width: 100%;
    }
}
.reset_variations{
    display: none !important;
}
// collections
section.two-collections {
    height: rem(840);
    @include media-breakpoint-down(lg) {
        height: auto;
        background-color: map-get($theme-colors, "secondary" );
    }
    .bg-collection{
        background: url(../img/bg-coll-r.svg) no-repeat 0 100%;
    }
    .bg-collection2{
        background: url(../img/bg-coll-r.svg) no-repeat 100% 0;
    }
    .col{
        @include media-breakpoint-down(lg) {
            padding: 0 !important;
            padding-top: 40px !important;
        }
    }
    .row.h-100{
        @include media-breakpoint-down(lg) {
            height: auto !important;
        }
    }
}
.card{
    &.alaune{
        .card-media{
            position: relative;
            padding-right: rem(20);
            position: relative;
            @include media-breakpoint-down(lg) {
                padding-right: 0;
            }
            .overflow-hidden{
                z-index: 4;
                &:before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 5;
                    transition: all 0.3s ease-in-out;
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%);
                    opacity: 0;
                }
            }
            .link-rounded{
                bottom: rem(10);
                right: rem(10);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                z-index: 6;
            }
        }

    }

    img{
        transition: all 0.3s ease-in-out;
    }
    .image{
        &>span.link-rounded{
            opacity: 0;
            transition: all 0.3s ease-in-out;
        }
    }
    &:hover{
        .card-media {
            .overflow-hidden {
                z-index: 4;
                &:before{
                    opacity: 1;
                }
            }
        }
        .image {
            &>span.link-rounded {
                opacity: 1;
            }
        }
        img{
            //transform: scale(1.05);
        }
    }
}
.search-filter{
    .input-group{
        height: 48px;
        line-height: 24px;
        @include media-breakpoint-down(lg){
            height: auto;
        }
    }
    .form-select{
        height: 48px;
        line-height: 24px;
        @include media-breakpoint-down(lg){
            height: auto;
        }
    }
}
.card-collection {
    width: rem(400);
    @include media-breakpoint-down(lg) {
        width: 100%;
    }
}
.bloc-collection{
    background-image: url(../img/bg-lines.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    @include media-breakpoint-down(lg) {
        background: none transparent;
    }
    .image-col{
        &:before {
            content: '';
            position: absolute;
            top: 0;
            display: block;
            background-color: map-get($theme-colors , 'grey-900');
            right: 0;
            width: 50%;
            height: 222px;
        }
        &.order-0{
            &:before {
                right: inherit;
                left: 0;
            }
        }
    }
    .content-col{
        &:before {
            content: '';
            position: absolute;
            top: 0;
            display: block;
            background-color: map-get($theme-colors , 'grey-900');
            left: 0;
            width: 50%;
            height: 40px;
        }
        &:after {
            content: '';
            position: absolute;
            left: 0;
            width: 50%;
            display: block;
            background-color: map-get($theme-colors , 'grey-900');
            height: 120px;
            bottom: 0;
        }
        &.order-1 {
            &:before {
                left: inherit;
                right: 0;
            }
            &:after{
                left: inherit;
                right: 0;
            }
        }
    }
    .rounded-top-c{
       @include media-breakpoint-down(lg) {
            border-radius: 0;
        }
    }
}
.blog-section{
    position: relative;
    &:before{
        content: '';
        width: 780px;
        height: 464px;
        flex-shrink: 0;
        background-color: map-get($theme-colors , 'grey-300' );
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        right: 50%;
        transform: translateX(-50%);
        z-index: -1;
       @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }
    .news-card{
        width: rem(560);
        height: rem(560);
        @include media-breakpoint-down(lg) {
            width: 100%;
            height: auto;
        }
    }
    .col.p-4{
        @include media-breakpoint-down(lg) {
            padding: 0 !important;
        }
    }
    .bloc-blog{
         @include media-breakpoint-down(lg) {
            padding: 24px !important;
         }
    }
    .list-group-item{
        line-height: 125%;
    }
}
.image-text {
    &:before{
        content: '';
        background: url(../img/bg-main.svg) no-repeat 50% 100%;
        background-size: cover;
        width: 100%;
        top: 100%;
        height: 703px;
        position: absolute;
        top: 376px;
        display: block;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: -1;
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
    .bloc-content{
        margin-top: rem(67);
        @include media-breakpoint-down(lg) {
            margin-top: 24px;
        }
        p{
            line-height: rem(20);
        }
    }
}

.video-section {
    p{
        //line-height: 125%;
    }
    iframe {
        max-width: 100%;
        max-height: auto;
    }
    .b-video{
        @include media-breakpoint-down(lg) {
            height: 220px;
        }
        iframe{
            @include media-breakpoint-down(lg) {
                max-width: inherit;
                height: 220px;
                position: absolute;
                left: 0;
            }

        }
    }
    .b-texte{
        @include media-breakpoint-down(lg) {
           
        }
        p{
            @include media-breakpoint-down(lg) {
                font-size: 16px;
            }
        }
    }
}
.intern-link, .link-light{
    font-size: 16px;
}
.bloc-artisanat{
    &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 68.42%;
        background-color: map-get($theme-colors, 'grey-300');
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        @include media-breakpoint-down(lg) {
            display: none;            
        }
    }
}
.timeline-section{
    // margin: 0 -1.8rem;
    .list-date{
        @include media-breakpoint-down(lg) {
            //overflow-x: scroll ;
        }
    }
        .tram-date {
            @include media-breakpoint-down(lg) {
            width: 100%;
            white-space: nowrap;
            overflow: auto;
            }
        }

    &:before{
        content: '';
        z-index: 2;
        position: absolute;
        width: 16.66%;
        top: rem(155);
        bottom: 0;
        background-color: map-get($theme-colors, "grey-900");
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
    .voile{
        position: relative;
        &:before{
            content: '';
                z-index: 2;
                position: absolute;
                width: 117%;
                top: rem(155);
                bottom: 0;
                background-color: map-get($theme-colors, "grey-900");
                left: -100%;
                @include media-breakpoint-down(lg) {
                    display: none;
                }
        }
    }
    .item{
        //width: rem(1400);
        //max-width: inherit;
        @include media-breakpoint-down(lg) {
            width: 94vw;
            max-width: 100%;
            flex-shrink: 1;
        }
        .sous-item{
            width: 400px;
            
            @include media-breakpoint-down(lg) {
                width: 87vw;
                flex-shrink: 1;
            }
        }
    }
    .owl-carousel .owl-stage-outer{
        overflow: initial !important;
        @include media-breakpoint-down(lg) {
            overflow: hidden !important;
        }
    }
    .date{
        cursor: pointer;
        @include media-breakpoint-down(lg) {
            display: inline-block;
        }
    }
}
.tree-columns{
    @include media-breakpoint-down(lg) {
        background-color: transparent !important;
    }
}
section.owl{
    overflow: hidden;
}

.fiche-produit{
    @include media-breakpoint-down(lg) {
        background-color: map-get($theme-colors, 'grey-300');
    }
}

.owl-arrows {
    position: absolute;
    top:0;
    left: 64px;
    transform: translateX(-12px);
    display: flex;
    flex-direction: column;
    height: 640px;
    @include media-breakpoint-down(lg) {
        height: auto;
        left: 0;
        top: 64px;
        transform: translateX(0) translateY(-12px);
        flex-direction: row;
        width: 100%;
    }
}

.arrow-up,
.arrow-down {
    position: absolute;
    top: 0;
    width: 24px;
    height: 24px;
    background-color: transparent;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    @include media-breakpoint-down(lg) {
        left: -40px;
        top: initial;
        bottom: initial;
        transform: rotate(-90deg);
    }
    i{
        &:before{
            font-size: 24px;
        }
    }
}
.arrow-down{
    top: initial;
    bottom: 0;
    @include media-breakpoint-down(lg) {
        bottom: initial;
        right: -40px;
        left: initial;
    }
}
.d-iframe {
    iframe {
        @include media-breakpoint-down(lg) {
            width: 100% !important;
            height: 356px;
        }
    }
}
.slideover{
    .owl-carousel {
            .owl-stage-outer {
                overflow: inherit !important;
                @include media-breakpoint-down(lg) {
                    overflow: hidden !important;
    
                }
    
            }
        }
}
.owl-carousel{
    .owl-stage-outer{
        // overflow: inherit !important;
        // @include media-breakpoint-down(lg) {
        //     overflow: hidden !important;

        // }

    }
    &.thumbnails-carousel{
        //padding-right: 89px;
        position: relative;
        height: 560px;
        overflow: hidden;
        margin: 40px 0;
        @include media-breakpoint-down(lg) {
            height: auto;
            margin: 0 0 40px;
        }
        .owl-stage-outer{
            overflow: hidden !important;
            .owl-stage{
                display: flex;
                flex-direction: column;
                @include media-breakpoint-down(lg) {
                    display: block;
                    flex-direction: row;
                }
            }
        }
        .item-miniature{
            width: 125px;
            height: 125px;
            margin-bottom: 16px;
            @include media-breakpoint-down(lg) {
                margin-bottom: 0;
                margin-right: 16px;
                width: auto;
                height: auto;
            }
            .img-cub{
                width: 100%;
                height: 100%;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                i{
                    width: 32px;
                    height: 32px;
                }
            }
        }
        &.main-carousel{
            .owl-stage-outer{
                overflow: hidden !important;
            }
        }
    }
    .owl-dots{
        position: relative;
        height: 4px;
        margin-top: rem(80);
        @include media-breakpoint-down(lg) {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            text-align: left;
            flex: 0 50%;
        }
        &:before{
            content: "";
            height: 4px;
            width: 100%;
            background-color: map-get($theme-colors , "grey-300" );
            display: block;
            position: absolute;
            border-radius: 8px;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
        button.owl-dot{
            span{
                border-radius: 8px;
                width: rem(100);
                height: 0;    
                transition: all 0.25s ease-in-out;
                background-color: rgba(0, 0, 0, 0);
                display: block;
                @include media-breakpoint-down(lg) {
                    width: auto;
                    flex: auto;
                }
            }
            height: 8px;    
            width: rem(100);
            padding: 2px !important;
            // border: 4px solid rgba(0,0,0,0) !important;
            vertical-align: top;
            z-index: 2;
            position: inherit;
            box-sizing: content-box;
            background-clip:border-box;
            margin-top: -4px;    
            @include media-breakpoint-down(lg) {
                width: auto;
                flex: auto;
            }
            &.active{
                span{
                    background-color: rgba($black,1);
                    height: 4px;
                }
                // border-top: 4px solid rgba(0, 0, 0, 1) !important;
            }
        }
    }
}
.page-garde{
    height: 100vh;
    .container{
        height: 100vh;
        .row{
            height: 70%;
        }
    }
}
//mobile
.fs-1{
    @include media-breakpoint-down(lg) {
        font-size: 32px !important;
    }
}
.fs-2{
    @include media-breakpoint-down(lg) {
        font-size: 32px !important;
    }
}
.fs-3{
    @include media-breakpoint-down(lg) {
        font-size: 32px !important;
    }
}
.fs-4{
    @include media-breakpoint-down(lg) {
        font-size: 24px !important;
    }
}
// .fs-5{
//     @include media-breakpoint-down(lg) {
//         font-size: 32px !important;
//     }
// }
.fs-6{
    @include media-breakpoint-down(lg) {
        font-size: 16px !important;
    }
}
.newsletter{
    .fs-3{
        @include media-breakpoint-down(lg) {
            font-size: 24px !important;
        }
    }
        .btn-primary{
            @include media-breakpoint-down(lg) {
                font-size: 0;
            }
        }
}
.navbar-toggler{
    border: 0 none;
    box-shadow: none !important;
    padding: 0;
    @include media-breakpoint-down(lg) {
        padding-right: 24px;
    }
    .navbar-toggler-icon {
        transition: all ease-in-out .2s;
    }
    &[aria-expanded="false"] {
        .navbar-toggler-icon {
            background-size: 24px;
            transition: all ease-in-out .2s;
        }
    }
    &[aria-expanded="true"]{
        .navbar-toggler-icon{
            --bs-navbar-toggler-icon-bg:#{escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'><path d='M19.1476 18.3525C19.2029 18.404 19.2472 18.4661 19.278 18.5351C19.3087 18.6041 19.3252 18.6786 19.3266 18.7541C19.3279 18.8296 19.314 18.9047 19.2857 18.9747C19.2574 19.0447 19.2153 19.1084 19.1619 19.1618C19.1085 19.2152 19.0449 19.2573 18.9748 19.2856C18.9048 19.3139 18.8298 19.3278 18.7542 19.3264C18.6787 19.3251 18.6042 19.3086 18.5352 19.2778C18.4662 19.2471 18.4041 19.2028 18.3526 19.1475L12.0001 12.7959L5.64763 19.1475C5.541 19.2469 5.39996 19.301 5.25424 19.2984C5.10851 19.2958 4.96947 19.2368 4.86641 19.1337C4.76335 19.0307 4.70432 18.8916 4.70175 18.7459C4.69918 18.6002 4.75327 18.4591 4.85263 18.3525L11.2042 12L4.85263 5.6475C4.75327 5.54087 4.69918 5.39983 4.70175 5.25411C4.70432 5.10838 4.76335 4.96934 4.86641 4.86628C4.96947 4.76322 5.10851 4.70419 5.25424 4.70162C5.39996 4.69905 5.541 4.75314 5.64763 4.8525L12.0001 11.2041L18.3526 4.8525C18.4593 4.75314 18.6003 4.69905 18.746 4.70162C18.8917 4.70419 19.0308 4.76322 19.1338 4.86628C19.2369 4.96934 19.2959 5.10838 19.2985 5.25411C19.3011 5.39983 19.247 5.54087 19.1476 5.6475L12.7961 12L19.1476 18.3525Z' fill='white' /></svg>"))};   
            background-color: map-get($theme-colors, 'primary' );
            border-radius: 100%;
            padding: 16px;
            width: 56px;
            height: 56px;
            background-size: 24px;
        }
    }
}
.cart-bloc {
    #quadmenu.quadmenu-default_theme:not(.quadmenu-is-horizontal) {
        .quadmenu-navbar-nav {
            @include media-breakpoint-down(lg) {
            display: flex;
            }
            li.quadmenu-item {
                .quadmenu-item-content {
                    @include media-breakpoint-down(lg) {
                        padding: 0 16px !important;
                    }
                    .quadmenu-icon{                        
                        @include media-breakpoint-down(lg) {
                            font-size: 18px !important;
                        }
                    }
                    &>.quadmenu-caret{
                        @include media-breakpoint-down(lg) {
                            padding: 0 !important;
                        }
                    }
                }
                &>a{
                    @include media-breakpoint-down(lg) {
                        font-size: 18px !important;
                    }
                }
            }
        }
    }
    .cart-full{

    }
}

#scrollbloc {
    width: 100%;
    height: 560px;
    overflow: hidden;
    @include media-breakpoint-down(lg) {
        overflow: initial;
        height: auto;
    }
    .contenu{
        &::-webkit-scrollbar{
            width: 4px;
        }
        &::-webkit-scrollbar-track {
            background-color: map-get($theme-colors, "grey-300");
        }
        &::-webkit-scrollbar-thumb {
            background-color: $black;
            border-radius: 8px;
        }

    }
}

.contenu {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.barre-defilement {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10px;
    background-color: #ccc;
}

.barre-defilement::-webkit-scrollbar {
    width: 0;
}
.wpcf7-list-item{
    margin-left: 0 !important;
}
.resultat-num{
    width: 48px;
    height: 48px;
    line-height: 1.2;
    display: block;
    &.bg-green{
        @include media-breakpoint-down(lg) {
            background-color: transparent !important;
        }
    }
    &.text-white{        
        @include media-breakpoint-down(lg) {
            color: map-get($theme-colors,"green" ) !important;
        }
    }
}
.resultat-card{
    &.revendeurs-agrees {
        .resultat-num {
            color: map-get($theme-colors, "ocre") !important;
            border-color: map-get($theme-colors, "ocre") !important;
        }
    }
    &:hover,&.active{
        background-color: white;
        @include media-breakpoint-down(lg) {
            background-color: transparent;
        }
        .resultat-num{
            background-color: map-get($theme-colors, "green") !important;
            color : white !important;
        }
        &.revendeurs-agrees{
            .resultat-num{
                background-color: map-get($theme-colors, "ocre") !important;
                color : white !important;
                border-color: map-get($theme-colors, "ocre") !important;
            }
        }
    }
}
#map{
    position: relative;
    @include media-breakpoint-down(lg) {
        height: 50vh !important;
    }
}
.marker {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M20 10C20 14.4183 14 22 12 22C10 22 4 14.4183 4 10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10Z' fill='%23204139'  /%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    cursor: pointer;
    &.revendeurs-agrees{
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M20 10C20 14.4183 14 22 12 22C10 22 4 14.4183 4 10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10Z' fill='%23BC9565'  /%3E%3C/svg%3E");            
    }
    &:hover, &.marker-active{
        background-image: url("data: image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' %3E%3Cpath d='M20 10C20 14.4183 14 22 12 22C10 22 4 14.4183 4 10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10Z' fill='%23FFF9F5' stroke='%23204139' /%3E%3C/svg%3E");
        background-size: contain;
        // &.revendeurs-agrees{
        //     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M20 10C20 14.4183 14 22 12 22C10 22 4 14.4183 4 10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10Z' fill='%23ffcc00'  /%3E%3C/svg%3E");            
        // }
    }
}
.mapboxgl-marker {
	position: absolute;
	top: 0;
	left: 0;
	will-change: transform;
	opacity: 1;
	transition: opacity .2s;
}
.mapboxgl-ctrl-bottom-right{
    display: none;
}
.contact-map{
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 24px;
    background-color: $white;
    line-height: 1;
    &:after{
        content: '';
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid $white;
        position: absolute;
        bottom: -12px;
        left: 50%;
        transform: translateX(-50%);
        //box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
    }
}
.points-vente{
    .filtre{
        i{
            width: 12px;
            height: 12px;
            display: inline-block;
        }
    }
}
//*--------woocommerce style override*/
div.woocommerce{
    background-color: white;
    padding: 24px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6
    {
        font-weight: bold;
        text-transform: uppercase;
    }
}
.woocommerce form.checkout_coupon,
.woocommerce form.login,
.woocommerce form.register {
    border: 0 none;
    padding: 20px;
    margin: 2em 0;
    text-align: left;
    border-radius: 0;
    background-color: white;
}
.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) #respond input#submit.alt,
.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) a.button.alt,
.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) button.button.alt,
.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) input.button.alt,
:where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce #respond input#submit.alt,
:where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce a.button.alt,
:where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce button.button.alt,
:where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce input.button.alt, .woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) #respond input#submit,
.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) a.button,
.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) button.button,
.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) input.button,
:where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce #respond input#submit,
:where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce a.button,
:where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce button.button,
:where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce input.button, .button
{
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-lg;
}

.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) button.button.alt.disabled {
    @extend .disabled;
}

.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) button.button.added:after,
.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) button.button.loading:after{
    display: none;
}
#add_payment_method table.cart input,
.woocommerce-cart table.cart input,
.woocommerce-checkout table.cart input, .woocommerce form .form-row input.input-text,
.woocommerce form .form-row textarea{
    @extend .form-control;
}
.woocommerce .quantity .qty{
    width: auto;
}
.woocommerce-message{
    border-top-color: map-get($theme-colors, "green" )
}
a{
    text-underline-offset: 0.375em !important;
    color: var(--bs-link-color-rgb);
}
.woocommerce-account .woocommerce-MyAccount-navigation {
    padding: 24px;
    background-color: var(--bs-green) !important;
    ul{
        @extend .list-group;
        li{
            list-style: none;
            a{
                @extend .list-group-item-action;
                @extend .list-group-item;
                @extend .fs-6;
                @extend .border-0;
                @extend .mb-1;
                @extend .d-flex;
                @extend .justify-content-between;
                @extend .align-items-center;
                @extend .p-4;
                @extend .bg-opacity-5;
                @extend .text-white;
                @extend .fw-bold;
                @extend .text-uppercase;
            }
            &.woocommerce-MyAccount-navigation-link--downloads{
                display: none !important;
            }
        }
    }
}
.woocommerce-form__input.woocommerce-form__input-checkbox, .wc-stripe-new-payment-method{
    @extend .form-check-input;
    margin-left: 0;
    margin-top: 0;
    margin-right: 8px;
}
.woocommerce-account .woocommerce-MyAccount-content{
    padding: 24px 24px 24px 0;

}
.woocommerce select{
    @extend .form-select;
}
.woocommerce label{
    @extend .form-label;
}

.select2-container--default .select2-selection--single{

}
.woocommerce form .form-row .input-checkbox{
    display: inline-block;
}
.woocommerce a.added_to_cart {
    padding-top: .5em;
    display: none !important;
}
.woocommerce-error,
.woocommerce-info,
.woocommerce-message {
    padding: 1em 2em 1em 3.5em;
    margin: 0 0 2em;
    position: relative;
    background-color: #f6f5f8;
    color: map-get($theme-colors, 'grey-700');
    border-top: 3px solid map-get($theme-colors, 'primary');
    list-style: none outside;
    width: auto;
    word-wrap: break-word;
    &:before{
        color: map-get($theme-colors, 'primary');
    }
}
.woocommerce-info {
    border-top-color: map-get($theme-colors, 'ocre');
    &:before{
        color: map-get($theme-colors, 'ocre');
    }
}
.woocommerce-Price-amount.amount:not(:first-child){
    font-size: 0 !important;
    padding-right: 0 !important;
}
.woocommerce-Price-amount.amount:first-child{
    font-size: 24px !important;
    padding-right: 8px;
    font-weight: bold;
}
.woocommerce-variation-price{
    margin-bottom: 24px;
}
.woocommerce-variation-description{
    p{
        margin-bottom: 0;
        font-weight: bold;
        font-size: 24px;
    }
}
.variations .woo-selected-variation-item-name{
    display: none !important;
}
//mega menu
#quadmenu .quadmenu-navbar-nav .quadmenu-dropdown-menu ul {
    list-style: none !important;
}
#quadmenu.quadmenu-is-horizontal .quadmenu-navbar-nav>li.quadmenu-item-type-tabs>.quadmenu-dropdown-menu>ul>li>ul.quadmenu-tabs {
    padding: 0 24px !important;
}
#quadmenu.quadmenu-default_theme.quadmenu-is-horizontal .quadmenu-navbar-nav>li.quadmenu-item-type-tabs>.quadmenu-dropdown-menu>ul>li>ul.quadmenu-tabs>li.quadmenu-item-type-tab {
    background-color: rgba(0, 0, 0, 0) !important;
    &.open{
        background-color: #F7F1ED !important;
    }
}
#quadmenu.quadmenu-is-horizontal .quadmenu-navbar-nav>li.quadmenu-item-type-tabs>.quadmenu-dropdown-menu>ul>li>ul.quadmenu-tabs>li.quadmenu-item-type-tab>.quadmenu-dropdown-menu {
    background-color: #F7F1ED !important;
}
#quadmenu.quadmenu-is-horizontal .quadmenu-navbar-nav>li.quadmenu-item-type-tabs>.quadmenu-dropdown-menu>ul>li>ul.quadmenu-tabs>li.quadmenu-item-type-tab>.quadmenu-dropdown-menu {
    background-color: #F7F1ED !important;
}
#quadmenu.quadmenu-is-horizontal .quadmenu-navbar-nav>li.quadmenu-item-type-tabs>.quadmenu-dropdown-menu>ul>li>ul.quadmenu-tabs>li.quadmenu-item-type-tab>.quadmenu-dropdown-menu {
    background-color: #F7F1ED !important;
}
#quadmenu.quadmenu-default_theme .quadmenu-navbar-nav .quadmenu-dropdown-menu li.quadmenu-item>a>.quadmenu-item-content {
    color: #000 !important;
    font-weight: bold !important;
}
#quadmenu .quadmenu-navbar-nav li.quadmenu-item.open>.quadmenu-dropdown-menu>ul{
    @include media-breakpoint-down(lg) {
        padding-left: 0;
    }
}
#quadmenu .quadmenu-navbar-nav .quadmenu-dropdown-menu ul{
    @include media-breakpoint-down(lg) {
        padding-left: 0;
    }
    
}
#quadmenu:not(.quadmenu-is-horizontal) .quadmenu-navbar-nav li.quadmenu-item.open:not(.closing)>.quadmenu-dropdown-menu{
    @include media-breakpoint-down(lg) {
        background-color: transparent;
    }
}
#quadmenu.quadmenu-default_theme:not(.quadmenu-is-horizontal) .quadmenu-navbar-nav li.quadmenu-item.open>a>.quadmenu-item-content>.quadmenu-caret{
    @include media-breakpoint-down(lg) {
        background-color: transparent;
        
    }
}
#quadmenu.quadmenu-default_theme:not(.quadmenu-is-horizontal) .quadmenu-navbar-nav li.quadmenu-item.open>a>.quadmenu-item-content>.quadmenu-caret{
    background-color: transparent !important;
    color: #000 !important;
}
//***************************

.woo-variation-swatches .variable-items-wrapper .variable-item:not(.radio-variable-item){
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 2px;
    padding: 0;
    margin: 0 8px;
}
.woocommerce-variation-add-to-cart{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 40px;
    .quantity{
        margin-right: 16px;
        .input-text{
            @extend .form-control;
            padding: 12px;
            height: 60px;
            width: 112px;
            border-radius: 40px;
        }
    }
    .single_add_to_cart_button{
        padding: 13px 32px !important;
        width: 70%;
        @include media-breakpoint-down(lg) {
            padding: 13px 2px !important;
        }
        &:before {
            content: '';
            display: inline-flex;
            width: 32px;
            height: 32px;
            vertical-align: middle;
            
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='33' height='32' viewBox='0 0 33 32' fill='none'><path d='M27.5 5.25H5.5C5.03587 5.25 4.59075 5.43437 4.26256 5.76256C3.93437 6.09075 3.75 6.53587 3.75 7V25C3.75 25.4641 3.93437 25.9092 4.26256 26.2374C4.59075 26.5656 5.03587 26.75 5.5 26.75H27.5C27.9641 26.75 28.4092 26.5656 28.7374 26.2374C29.0656 25.9092 29.25 25.4641 29.25 25V7C29.25 6.53587 29.0656 6.09075 28.7374 5.76256C28.4092 5.43437 27.9641 5.25 27.5 5.25ZM27.75 25C27.75 25.0663 27.7237 25.1299 27.6768 25.1768C27.6299 25.2237 27.5663 25.25 27.5 25.25H5.5C5.4337 25.25 5.37011 25.2237 5.32322 25.1768C5.27634 25.1299 5.25 25.0663 5.25 25V7C5.25 6.9337 5.27634 6.87011 5.32322 6.82322C5.37011 6.77634 5.4337 6.75 5.5 6.75H27.5C27.5663 6.75 27.6299 6.77634 27.6768 6.82322C27.7237 6.87011 27.75 6.9337 27.75 7V25ZM22.25 11C22.25 12.525 21.6442 13.9875 20.5659 15.0659C19.4875 16.1442 18.025 16.75 16.5 16.75C14.975 16.75 13.5125 16.1442 12.4341 15.0659C11.3558 13.9875 10.75 12.525 10.75 11C10.75 10.8011 10.829 10.6103 10.9697 10.4697C11.1103 10.329 11.3011 10.25 11.5 10.25C11.6989 10.25 11.8897 10.329 12.0303 10.4697C12.171 10.6103 12.25 10.8011 12.25 11C12.25 12.1272 12.6978 13.2082 13.4948 14.0052C14.2918 14.8022 15.3728 15.25 16.5 15.25C17.6272 15.25 18.7082 14.8022 19.5052 14.0052C20.3022 13.2082 20.75 12.1272 20.75 11C20.75 10.8011 20.829 10.6103 20.9697 10.4697C21.1103 10.329 21.3011 10.25 21.5 10.25C21.6989 10.25 21.8897 10.329 22.0303 10.4697C22.171 10.6103 22.25 10.8011 22.25 11Z' fill='white' /></svg>");
            margin-right: 12px;
        }
    }
}
.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) #respond input#submit.alt:hover,
.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) a.button.alt:hover,
.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) button.button.alt:hover,
.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) input.button.alt:hover,
:where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce #respond input#submit.alt:hover,
:where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce a.button.alt:hover,
:where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce button.button.alt:hover,
:where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce input.button.alt:hover {
    background-color: map-get($theme-colors, 'ocre' );
    color: #fff;
}
.icon-cart {
    &.cart-full {
        position: relative;

        &:after {
            content: '';
            width: 12px;
            height: 12px;
            border: 2px solid #fff;
            background-color: map-get($theme-colors , "ocre");
            display: block;
            position: absolute;
            border-radius: 100%;
            top: -1px;
            right: -3px;
        }
    }
}
.variations{
    width: 100%;
    tr{
        display: block;
        padding-bottom: 24px;
        th.label{
            display: block;
            .woo-variation-swatches.variable-items-wrapper{
                margin-bottom: 24px;
            }
        }
    }
}

//color picker
.woo-variation-swatches {
    .variable-items-wrapper {
        .variable-item:not(.radio-variable-item) {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            cursor: pointer;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            position: relative;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background-color: var(--wvs-item-background-color, #FFFFFF);
            color: var(--wvs-item-text-color, #000000);
            box-shadow: var(--wvs-item-box-shadow, 0 0 0 2px #00000010);
            padding: 0;
            margin: 8px;
            width: var(--wvs-single-product-item-width, 30px);
            height: var(--wvs-single-product-item-height, 30px);

            &:first-child {
                // margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &.no-stock {

                img,
                span,
                .variable-item-contents {
                    opacity: 0.6;
                }

                .variable-item-contents::before {
                    position: absolute;
                    content: " ";
                    display: block;
                    background-image: var(--wvs-cross);
                    background-repeat: no-repeat;
                    background-position: center center;
                    height: 100%;
                    width: 100%;
                }
            }

            &:hover {
                -webkit-box-shadow: var(--wvs-hover-item-box-shadow, 0 0 0 2px #000);
                box-shadow: var(--wvs-hover-item-box-shadow, 0 0 0 2px #000);
                background-color: var(--wvs-hover-item-background-color, #FFFFFF);
                color: var(--wvs-hover-item-text-color, #000000);
            }

            &:focus {
                -webkit-box-shadow: var(--wvs-hover-item-box-shadow, 0 0 0 2px #000);
                box-shadow: var(--wvs-hover-item-box-shadow, 0 0 0 2px #000);
            }

            &.selected,
            &.selected:hover {
                -webkit-box-shadow: var(--wvs-selected-item-box-shadow, 0 0 0 2px #000);
                box-shadow: var(--wvs-selected-item-box-shadow, 0 0 0 2px #000);
                color: var(--wvs-selected-item-text-color, #000000);
            }

            &.disabled,
            &.disabled:hover {
                pointer-events: none;
                cursor: not-allowed;
                position: relative;
                overflow: hidden;

                img,
                span,
                .variable-item-contents,
                &:hover img,
                &:hover span,
                &:hover .variable-item-contents {
                    opacity: 0.6;
                }

                .variable-item-contents::before,
                &:hover .variable-item-contents::before {
                    position: absolute;
                    content: " ";
                    display: block;
                    background-image: var(--wvs-cross);
                    background-repeat: no-repeat;
                    background-position: center center;
                    height: 100%;
                    width: 100%;
                }
            }

            &.color-variable-item.selected:not(.no-stock),
            &.image-variable-item.selected:not(.no-stock) {
                .variable-item-contents::before {
                    position: absolute;
                    content: " ";
                    display: block;
                    background-image: none;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 60%;
                    height: 100%;
                    width: 100%;
                }
            }

            &.color-variable-item .variable-item-span-color,
            &.image-variable-item .variable-item-span-color {
                width: 100%;
                height: 100%;
                // position: relative;
                display: block;
            }
        }

        &.button-variable-item {
            text-align: center;
            font-size: var(--wvs-single-product-item-font-size, 16px);

            &.selected:not(.no-stock) {
                background-color: var(--wvs-selected-item-background-color, #FFFFFF);
                color: var(--wvs-selected-item-text-color, #000000);
            }
        }
    }
}


//lightbox

body.lb-disable-scrolling {
    overflow: hidden;
}

.lightboxOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: black;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8;
    display: none;
}

.lightbox {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 10000;
    text-align: center;
    line-height: 0;
    font-weight: normal;
    outline: none;
}

.lightbox .lb-image {
    display: block;
    height: auto;
    max-width: inherit;
    max-height: none;
    border-radius: 3px;

    /* Image border */
    border: 4px solid white;
}

.lightbox a img {
    border: none;
}

.lb-outerContainer {
    position: relative;
    *zoom: 1;
    width: 250px;
    height: 250px;
    margin: 0 auto;
    border-radius: 4px;

    /* Background color behind image.
     This is visible during transitions. */
    background-color: white;
}

.lb-outerContainer:after {
    content: "";
    display: table;
    clear: both;
}

.lb-loader {
    position: absolute;
    top: 43%;
    left: 0;
    height: 25%;
    width: 100%;
    text-align: center;
    line-height: 0;
}

.lb-cancel {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    background: url(../images/loading.gif) no-repeat;
}

.lb-nav {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
}

.lb-container>.nav {
    left: 0;
}

.lb-nav a {
    outline: none;
    background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
}

.lb-prev,
.lb-next {
    height: 100%;
    cursor: pointer;
    display: block;
}

.lb-nav a.lb-prev {
    width: 34%;
    left: 0;
    float: left;
    background: url(../images/prev.png) left 48% no-repeat;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity 0.6s;
    -moz-transition: opacity 0.6s;
    -o-transition: opacity 0.6s;
    transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}

.lb-nav a.lb-next {
    width: 64%;
    right: 0;
    float: right;
    background: url(../images/next.png) right 48% no-repeat;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity 0.6s;
    -moz-transition: opacity 0.6s;
    -o-transition: opacity 0.6s;
    transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}

.lb-dataContainer {
    margin: 0 auto;
    padding-top: 5px;
    *zoom: 1;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
    content: "";
    display: table;
    clear: both;
}

.lb-data {
    padding: 0 4px;
    color: #ccc;
}

.lb-data .lb-details {
    width: 85%;
    float: left;
    text-align: left;
    line-height: 1.1em;
}

.lb-data .lb-caption {
    font-size: 13px;
    font-weight: bold;
    line-height: 1em;
}

.lb-data .lb-caption a {
    color: #4ae;
}

.lb-data .lb-number {
    display: block;
    clear: left;
    padding-bottom: 1em;
    font-size: 12px;
    color: #999999;
}

.lb-data .lb-close {
    display: block;
    float: right;
    width: 30px;
    height: 30px;
    background: url(../images/close.png) top right no-repeat;
    text-align: right;
    outline: none;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
    cursor: pointer;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}

//lightboxed
.lightboxed {
    cursor: pointer;
}

div#lightboxed--cache {
    position: fixed;
    left: -99999999px;
}

div#lightboxed--container {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    background: rgba(0, 0, 0, 0.9);
    z-index: 9001;
}

div#lightboxed--stage {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

div#lightboxed--content {
    position: absolute;
    top: 0;
    left: 0;
}

div#lightboxed--content .lightboxed--frame {
    position: absolute;
    top: 0;
    left: 0;
}

div#lightboxed--content .lightboxed--frame img,
div#lightboxed--content .lightboxed--frame iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border: 0;
}

div#lightboxed--content .lightboxed--frame .lightboxed--caption {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
    background: #000;
    background: rgba(0, 0, 0, 0.9);
    color: #fff !important;
}

div#lightboxed--thumbs {
    display: none;
    position: absolute;
    bottom: 0;
    left: 50%;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

div#lightboxed--thumbs .lightboxed--thumb {
    float: left;
    overflow: hidden;
    border-radius: 2px;
    cursor: pointer;
}

div#lightboxed--thumbs .lightboxed--thumb .lightboxed--thumb_border {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    background: rgba(255, 255, 255, 0.4);
    transition: 500ms background;
}

div#lightboxed--thumbs .lightboxed--thumb.lightboxed--active .lightboxed--thumb_border {
    background: rgba(255, 255, 255, 0.7);
    transition: 500ms background;
}

div#lightboxed--thumbs .lightboxed--thumb img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

div#lightboxed--bttn_close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    background: rgba(255, 255, 255, 0.6);
    background-image: url(../img/controls.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 100%;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    cursor: pointer;
}

div#lightboxed--bttn_next {
    display: none;
    position: absolute;
    top: 50%;
    right: 10px;
    background: #fff;
    background: rgba(255, 255, 255, 0.6);
    background-image: url(../img/controls.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 50%;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    cursor: pointer;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

div#lightboxed--bttn_prev {
    display: none;
    position: absolute;
    top: 50%;
    left: 10px;
    background: #fff;
    background: rgba(255, 255, 255, 0.6);
    background-image: url(../img/controls.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 0%;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    cursor: pointer;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}


.columns {
    position: relative;
}

.columns .item {
    font-weight: 300;
    overflow: hidden;
    background: #ffffff;
    font-size: 13px;
    color: #212121;
    img{
        width: 100% !important;
        height: auto !important;
    }
}

.gallery-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}